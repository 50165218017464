/* You can add global styles to this file, and also import other style files */
@import "~bs-stepper/dist/css/bs-stepper.min.css";
@import "@coreui/coreui/scss/coreui";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "assets/scss/layout";
@import "assets/scss/fixes";
@import "assets/scss/examples";


.breadcrumb-item a{
  text-decoration: none !important;
}