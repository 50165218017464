@import "scrollbar";


.form-floating>.form-select {
  padding-top: 1.5rem !important;
  padding-bottom: 0 !important;
}

// .breadcrumb-item a{
//   text-decoration: none !important;
// }

.smartpricing-textarea{
  border: 1px solid #ced4da; 
  border-radius: 0.375rem;
  max-width: calc(100% - var(--bs-gutter-x)) !important;
  margin-left: calc(var(--bs-gutter-x) * 0.5);
}
.smartpricing-textarea > textarea {
  width:100%; 
  resize:vertical; 
  border: none; 
  margin-top: 25px;
}
.smartpricing-textarea > label {
  opacity: 0.65; 
  transform: scale(0.8) translateY(-0.5rem) translateX(0.15rem) !important;
}

.p-multiselect-panel{
  position: absolute !important;
  
}
// .p-dropdown-panel{
//   position: static !important;
//   //top: 0%;
//   //bottom: 0%;;
// }